import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e31dc65"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = {
  type: "button",
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal_add_quick_settlement"
}
const _hoisted_12 = { class: "svg-icon svg-icon-2" }
const _hoisted_13 = { class: "card-body pt-0" }
const _hoisted_14 = { class: "text-nowrap" }
const _hoisted_15 = { class: "text-nowrap" }
const _hoisted_16 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_AddModal = _resolveComponent("AddModal")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-14",
              placeholder: _ctx.t('common.search')
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.search]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.filter")), 1)
            ]),
            _createVNode(_component_FilterDropdown, {
              onSubmitFilter: _ctx.handleFilter,
              onResetFilter: _ctx.handleFilterReset
            }, null, 8, ["onSubmitFilter", "onResetFilter"]),
            _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.add")) + _toDisplayString(_ctx.t("common.quickSettlement")), 1)
            ])), [
              [_directive_auth, { auth: ['add'] }]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_MBDatatable, {
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "current-page": _ctx.currentPage,
          total: _ctx.total,
          "rows-per-page": _ctx.pageSize,
          order: "desc",
          onCurrentChange: _ctx.onCurrentPageChange,
          onItemsPerPageChange: _ctx.onRowsPerPageChange,
          onSort: _ctx.onColumnSort
        }, {
          "cell-type": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(_ctx.options.type.get(item.order_type)), 1)
          ]),
          "cell-created_at": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(_ctx.formatDateTime(item.created_at)), 1)
          ]),
          "cell-due_date": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(item.due_date)), 1)
          ]),
          "cell-order_number": _withCtx(({ row: item }) => [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.splitString(item.order_number), (split, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text-nowrap",
                  key: i
                }, _toDisplayString(split), 1))
              }), 128))
            ])
          ]),
          "cell-customer": _withCtx(({ row: item }) => [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.splitString(item.customer_name), (split, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text-nowrap",
                  key: i
                }, _toDisplayString(split), 1))
              }), 128))
            ])
          ]),
          "cell-amount": _withCtx(({ row: item }) => [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.t("quickSettlement.orderAmount")) + ": " + _toDisplayString(item.order_currency == "CNY"
                ? _ctx.$currency.currency(
                    item.order_amount,
                    2,
                    ".",
                    "",
                    item.order_currency + " "
                  )
                : _ctx.$currency.currency(
                    item.order_amount,
                    2,
                    ",",
                    ".",
                    item.order_currency + " "
                  )), 1),
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t("quickSettlement.paidAmount")) + ": " + _toDisplayString(item.pay_currency == "CNY"
                ? _ctx.$currency.currency(
                    item.pay_amount,
                    2,
                    ".",
                    "",
                    item.pay_currency + " "
                  )
                : _ctx.$currency.currency(
                    item.pay_amount,
                    2,
                    ",",
                    ".",
                    item.pay_currency + " "
                  )), 1)
          ]),
          "cell-order_status": _withCtx(({ row: item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["badge", _ctx.statusClass(item.order_status)])
            }, _toDisplayString(_ctx.options.order_status.get(item.order_status)), 3)
          ]),
          "cell-payment_status": _withCtx(({ row: item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["badge", _ctx.statusClass(item.status)])
            }, _toDisplayString(_ctx.options.payment_status.get(item.status)), 3)
          ]),
          "cell-actions": _withCtx(({ row: item }) => [
            _createVNode(_component_router_link, {
              to: '/quick-settlement/' + item.id + '/Information',
              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_16, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
      ])
    ]),
    _createVNode(_component_AddModal, { onUpdateList: _ctx.updateList }, null, 8, ["onUpdateList"])
  ], 64))
}