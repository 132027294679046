
import { defineComponent, ref, onMounted, onActivated, computed } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/quick-settlement/FilterDropdown.vue";
import AddModal from "@/views/quick-settlement/AddModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiQuickSettlement } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import store from "@/store";
import {
  commonChangeFilterAddDate,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface QuickSettlement {
  created_at: string;
  due_date: string;
  order_type: string;
  order_number: string;
  customer_id: string;
  order_amount: string;
  order_currency: string;
  pay_amount: string;
  pay_currency: string;
  distribution_model: string;
  remark: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string | Array<string>;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "quick-settlement",
  components: {
    MBDatatable,
    FilterDropdown,
    AddModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<QuickSettlement>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("quickSettlement.type"),
        key: "type",
        sortable: false,
      },
      {
        name: t("quickSettlement.creationDate"),
        key: "created_at",
        sortable: true,
      },
      {
        name: t("quickSettlement.dueDate"),
        key: "due_date",
        sortable: true,
      },
      {
        name: t("quickSettlement.orderNumber"),
        key: "order_number",
        sortable: false,
      },
      {
        name: t("common.customer"),
        key: "customer",
        sortable: false,
      },
      {
        name: t("quickSettlement.amount"),
        key: "amount",
        sortable: false,
      },
      {
        name: t("quickSettlement.orderStatus"),
        key: "order_status",
        sortable: false,
      },
      {
        name: t("quickSettlement.paymentStatus"),
        key: "payment_status",
        sortable: false,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      type: new Map([
        [1, t("quickSettlement.typeSale")],
        [2, t("quickSettlement.typeDeposit")],
      ]),
      distribution_model: new Map([
        [1, t("quickSettlement.distributionModelGrossProfitMode")],
        [2, t("quickSettlement.distributionModelTransactionMode")],
      ]),
      order_status: new Map([
        [1, t("quickSettlement.orderStatusNew")],
        [2, t("quickSettlement.orderStatusProcessed")],
        [3, t("quickSettlement.orderStatusVoid")],
      ]),
      payment_status: new Map([
        [1, t("quickSettlement.paymentStatusPending")],
        [2, t("quickSettlement.paymentStatusPaid")],
      ]),
    });

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getQuickSettlementList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getQuickSettlementList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiQuickSettlement.getQuickSettlementList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const formatDate = (date) => {
      return date ? moment(date).utcOffset(2).format("YYYY-MM-DD") : "";
    };

    const formatDateTime = (date) => {
      return date
        ? moment(date).utcOffset(2).format("YYYY-MM-DD HH:mm:ss")
        : "";
    };

    const updateList = () => {
      getQuickSettlementList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getQuickSettlementList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      getQuickSettlementList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getQuickSettlementList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item.includes("created_at")) {
            const isStart = item.includes("_start") ? true : false,
              date = isStart
                ? commonChangeFilterAddDate(val[item])
                : commonChangeFilterAddDate(val[item], 1);
            filterArr.push({
              field: "created_at",
              value: date,
              condition: isStart ? "gteq" : "lt",
            });
          } else if (item == "order_number") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      getQuickSettlementList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getQuickSettlementList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getQuickSettlementList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const statusClass = (val) => {
      let statusClass = "";

      switch (val) {
        case 1:
          statusClass = "badge-light-primary";
          break;
        case 2:
          statusClass = "badge-light-success";
          break;
        case 3:
          statusClass = "badge-light-danger";
          break;
        default:
          statusClass = "badge-white";
          break;
      }
      return statusClass;
    };

    const splitString = computed(() => {
      return (string) => {
        return string.split(",");
      };
    });

    return {
      t,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      options,
      getQuickSettlementList,
      formatDate,
      formatDateTime,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      splitString,
    };
  },
});
