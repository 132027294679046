
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
} from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiQuickSettlement } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import { ElUpload } from "element-plus";
import ElSelect from "element-plus/es/components/select";
import moment from "moment";
import { commonChangeDefaultDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "add-stock-in-modal",
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const addQuickSettlementModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      order_type: "",
      customer: {},
      customer_id: "",
      customer_name: "",
      order_amount: 0.0,
      order_currency: "EUR",
      pay_amount: 0.0,
      pay_currency: "EUR",
      distribution_model: "",
      due_date: null,
      comment: "",
      file_id: 0,
    });

    const options = ref({
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
      customer: [],
      customerLoading: false,
      type: [
        {
          label: t("quickSettlement.typeSale"),
          value: "1",
        },
        {
          label: t("quickSettlement.typeDeposit"),
          value: "2",
        },
      ],
      distribution_model: [
        {
          label: t("quickSettlement.distributionModelGrossProfitMode"),
          value: "1",
        },
        {
          label: t("quickSettlement.distributionModelTransactionMode"),
          value: "2",
        },
      ],
      order_status: [
        {
          label: t("quickSettlement.orderStatusNew"),
          value: "1",
        },
        {
          label: t("quickSettlement.orderStatusProcessed"),
          value: "2",
        },
        {
          label: t("quickSettlement.orderStatusVoid"),
          value: 3,
        },
      ],
      payment_status: [
        {
          label: t("quickSettlement.paymentStatusPending"),
          value: "1",
        },
        {
          label: t("quickSettlement.paymentStatusPaid"),
          value: "2",
        },
      ],
    });

    const rules = ref({
      order_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      customer_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      order_amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pay_amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      distribution_model: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      due_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const selectCustomer = ref<InstanceType<typeof ElSelect>>();
    const currentInstance: any = getCurrentInstance();
    const uploadUrl = ApiQuickSettlement.uploadQuickSettlementFile();
    const uploadRefs = reactive({
      file_id: ref<InstanceType<typeof ElUpload>>(),
    });

    const removeFlag = ref(false);
    let childWin: any;

    const uploadDisabled = ref({
      file_id: false,
    });

    const uploadData = ref({
      file_id: [] as any[],
    });

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const handleProgress = (e, file, fileList, attributes) => {
      uploadDisabled.value[attributes] = true;
    };

    const handleSuccess = (response, file, fileList, sign, data) => {
      uploadDisabled.value[sign] = false;
      if (response.code !== 0) {
        const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
        if (uploadData.value[sign].length == 0) {
          refModal!.handleRemove(file, file.raw);
        } else {
          let params = {
            ...uploadData.value[sign][0],
            uid: moment().valueOf(),
          };
          refModal!.clearFiles();
          refModal!.handleStart(params);
        }
        Swal.fire({
          text: t("quickSettlement.uploadError"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      uploadData.value[sign] = fileList;
      data[sign] = response.data.file_id;
    };

    const handleError = (err, file, fileList, attributes) => {
      if (err) {
        uploadDisabled.value[attributes] = false;
        Swal.fire({
          text: t("common.uploadErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const handleExceed = (files, fileList, sign) => {
      const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
      const file = files[0];
      refModal!.clearFiles();
      refModal!.handleStart(file);
      refModal!.submit();
    };

    const handleRemove = (file, fileList, sign, data, options, removeFlag) => {
      const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
      // 上传文件超出限制会在页面上删除文件，这里重新将之前的文件回显
      if (removeFlag && data[sign]) {
        refModal!.handleStart(options[sign][0]);
        return;
      }
      data[sign] = 0;
    };

    const handleBeforeUpload = (file) => {
      const limit = 1024 * 1024 * 5;
      if (file.size > limit) {
        Swal.fire({
          text: t("common.uploadSizeErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          removeFlag.value = false;
        });
        removeFlag.value = true;
        return false;
      }
    };

    const cancelReadonly = () => {
      removeReadonly();
    };

    const removeReadonly = () => {
      selectCustomer.value?.$el
        .querySelector(".el-input__inner")
        .removeAttribute("readonly");
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const customerChange = (e) => {
      formData.value.customer_id = e.value;
      formData.value.customer_name = e.label;
    };

    // const getTaggingData = async () => {
    //   const { data } = await ApiBase.getTaggingData({
    //     short_key: ["sales_order_distribution_mode"],
    //   });
    //   if (data.code == 0) {
    //     options.value.distribution_model =
    //       data.data.sales_order_distribution_mode.items;
    //   }
    // };

    const getFromInfo = () => {
      Promise.all([])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiQuickSettlement.addQuickSettlement(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addQuickSettlementModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(addQuickSettlementModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value = {
        order_type: "",
        customer: {},
        customer_id: "",
        customer_name: "",
        order_amount: 0.0,
        order_currency: "EUR",
        pay_amount: 0.0,
        pay_currency: "EUR",
        distribution_model: "",
        due_date: null,
        comment: "",
        file_id: 0,
      };
      removeFlag.value = false;
      uploadData.value = {
        file_id: [],
      };
      uploadDisabled.value = {
        file_id: false,
      };
      emit("reset-form");
    };

    const updateList = () => {
      handleDiscard();
      emit("update-list");
    };

    onMounted(() => {
      removeReadonly();
      getFromInfo();
    });

    return {
      t,
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      formRef,
      addQuickSettlementModalRef,
      submitButton,
      options,
      submit,
      handleDiscard,
      resetForm,
      updateList,
      searchCustomerItems,
      customerChange,
      removeFlag,
      uploadRefs,
      uploadHeaders,
      uploadUrl,
      uploadDisabled,
      uploadData,
      handleProgress,
      handleSuccess,
      handleError,
      handleExceed,
      handleRemove,
      handleBeforeUpload,
      selectCustomer,
      cancelReadonly,
    };
  },
});
